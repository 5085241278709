




























import Page from '@/calendesk/models/DTO/Response/Page'
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'AppFooterMenu',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages'
    }),
    menuList (): Record<string, any> {
      return [
        {
          name: this.data.configuration.wb_menu_title__text__,
          key: 'footer-menu',
          items: [...this.pageList(this.data.configuration.wb_menu_column_1__page_ids__)]
        },
        {
          name: '',
          key: 'footer-menu2',
          items: [...this.pageList(this.data.configuration.wb_menu_column_2__page_ids__)]
        }
      ]
    }
  },
  methods: {
    pageList (page: Array<string>): Page[] {
      const pageList: Page[] = new Array<Page>()
      if (page && Array.isArray(page)) {
        page.forEach((uuid: string) => {
          const page: Page | undefined = this.draftPages.find((_: Page) => _.uuid === uuid)
          if (page) {
            pageList.push(page)
          }
        })
      }

      return pageList
    }
  }
})
